@import url('https://fonts.googleapis.com/css?family=Anton');

:root {
  --topbar-height: 80px;
  --map-height: 700px;
  --mobile-map-height: 600px;
  --topbar-negative-height: -80px;
  --bottombar-height: 260px;
  --padding: 0 25px;
}
