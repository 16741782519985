@import url(https://fonts.googleapis.com/css?family=Anton);
:root {
  --dark: #404040;
  --light-gray: #a2a2a2;
  --white: #ffffff;
  --highlight: #5aaadf;
  --heading-color: var(--highlight);
}
@font-face {
  font-family: "Albert";
  src: url("http://www.tsv1860.de/i/fonts/fs_albert_pro-bold_0-webfont.woff2");
}

*,
*:after,
*:before {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}

body {
  overflow: scrollY;
  color: var(--dark);
  font-smoothing: antialiased;
  padding: 0;
  margin: 0;
}

html * {
  font-family: 'Albert', sans-serif !important;
}

p, div {
  font-family: 'Albert', sans-serif !important;
}

input {
  border-radius: 0;
  border: none;
}

h1 {
  font-size: 18px;
  font-family: Anton,sans-serif!important;
  font-weight: 400 !important;
  letter-spacing: 1px;
}
h2 {
  font-size: 15px;
  font-family: Anton,sans-serif!important;
  font-weight: 400 !important;
  letter-spacing: 1.5px;
}
h3 {
  font-size: 16px;
  font-weight: 400 !important;
}
h4 {
  font-size: 32px;
  font-weight: 400 !important;
  font-family: 'Anton', sans-serif !important;
  letter-spacing: 1.5px;
}

@media only screen and (min-width: 768px) {
  h1 {
    font-size: 30px;
  }
  h2 {
    font-size: 16px;
  }
  h3 {
    font-size: 14px;

  }
}

:global(.slick-list){
  overflow: visible !important;
}

:global(.slick-track) {
/*height: 200px;*/
}

:global(.slick-slide) {
  float: none !important;
  display: inline-block !important;
  vertical-align: bottom !important ;
}

:root {
  --topbar-height: 80px;
  --map-height: 700px;
  --mobile-map-height: 600px;
  --topbar-negative-height: -80px;
  --bottombar-height: 260px;
  --padding: 0 25px;
}

:root {
  --dark: #404040;
  --light-gray: #a2a2a2;
  --white: #ffffff;
  --highlight: #5aaadf;
  --heading-color: var(--highlight);
}
.styles_bigTopBar__3u-RJ {
  z-index: 20;
  width: 100%;
  min-height: 325px;
  background-image: url(/static/media/banner.8221ee68.jpg);
  background-size: cover;
  align-content: center;
  color: black;
  border-bottom: 30px solid black;

}
.styles_bigTopBar__3u-RJ h1 {
  text-transform: uppercase;
  margin: 0;
  text-align: center;
  color: white;
  font-size: 40px;
}
.styles_bigTopBar__3u-RJ p {
  text-align: center;
  font-weight: bold;
  color: white;
}

.styles_bigTopBar__3u-RJ .styles_mainLogo__3Ccck {
  width: 75px;
}

.styles_bigTopBar__3u-RJ .styles_titleTexts__1WtlS {
  display: inline-block;
  margin-left: 0;
  margin-top: 20px;
  width: 100%;
}

.styles_bigTopBar__3u-RJ .styles_extendedTopBar__3hajN {
  top: var(--topbar-height);
  margin-top: var(--topbar-height);
  width: 100%;
  height: 80%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  align-content: center;
  text-align: center;
  padding-top: 120px;

}
.styles_bigTopBar__3u-RJ .styles_extendedTopBar__3hajN .styles_inputWrapper__3DzVu{
  background: white;
  display: inline-block;
  width: 80%;
  height: 20px;

}
.styles_bigTopBar__3u-RJ .styles_extendedTopBar__3hajN .styles_inputWrapper__3DzVu:before,
.styles_bigTopBar__3u-RJ .styles_extendedTopBar__3hajN .styles_inputWrapper__3DzVu:after{
  content: "";
  position: absolute;
  left: 0; 
  right: 19%;
  bottom: 100%;
  border-bottom: 14px solid white;
  border-left: 15px solid white;
  border-right: none;
}
.styles_bigTopBar__3u-RJ .styles_extendedTopBar__3hajN .styles_inputWrapper__3DzVu:before{
  border-left: 15px solid white;
}
.styles_bigTopBar__3u-RJ .styles_extendedTopBar__3hajN .styles_inputWrapper__3DzVu:after{
  border-right: 15px solid white;
  bottom: auto;
  top: 100%;
  border-bottom: none;
  border-top: 15px solid white;
}

.styles_bigTopBar__3u-RJ .styles_extendedTopBar__3hajN input {
  display: block;
  height: 20px;
  padding: 0 20px;
  border: none;
  margin: 0;
  width: 100%;
  font-weight: bold;
  color: var(--heading-color);
  outline: none;
}
.styles_bigTopBar__3u-RJ .styles_extendedTopBar__3hajN input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: var(--heading-color);
}
.styles_bigTopBar__3u-RJ .styles_extendedTopBar__3hajN input::-moz-placeholder { /* Firefox 19+ */
  color: var(--heading-color);
}
.styles_bigTopBar__3u-RJ .styles_extendedTopBar__3hajN input:-ms-input-placeholder { /* IE 10+ */
  color: var(--heading-color);
}
.styles_bigTopBar__3u-RJ .styles_extendedTopBar__3hajN input:-moz-placeholder { /* Firefox 18- */
  color: var(--heading-color);
}

.styles_bigTopBar__3u-RJ .styles_extendedTopBar__3hajN button {
  position: relative;
  display: inline-block;
  cursor: pointer;
  height: 20px;
  width: 20%;
  border: none;
  background-color: black;
  color: white;
  font-weight: bold;
  margin-right: -1px;
}

.styles_bigTopBar__3u-RJ .styles_extendedTopBar__3hajN button:before,
.styles_bigTopBar__3u-RJ .styles_extendedTopBar__3hajN button:after{
  content: "";
  position: absolute;
  left: 0; 
  right: 0;
  bottom: 100%;
  border-bottom: 15px solid black;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
}
.styles_bigTopBar__3u-RJ .styles_extendedTopBar__3hajN button:before{
  border-left: 15px solid black;
}
.styles_bigTopBar__3u-RJ .styles_extendedTopBar__3hajN button:after{
  border-right: none;
  bottom: auto;
  top: 100%;
  border-left: none;
  border-bottom: 15px solid transparent;
  border-top: 15px solid black;
}
.styles_bigTopBar__3u-RJ .styles_extendedTopBar__3hajN button:hover {
  color: var(--heading-color);
}

.styles_bigTopBar__3u-RJ .styles_headerTitle__22Tb8 img {
  cursor: pointer;
}
.styles_bigTopBar__3u-RJ .styles_headerTitle__22Tb8 .styles_headerLogo__1MleN {
  cursor: pointer;
}

.styles_bigTopBar__3u-RJ .styles_headerLogo__1MleN {
  color: var(--heading-color);
  font-size: 20px;
  font-family: Anton,sans-serif!important;
  font-weight: 400 !important;
  letter-spacing: 1px;
  margin-left: 14px;
  text-transform: uppercase;
}

.styles_bigTopBar__3u-RJ .styles_searchLabel__1_eVU {
  display: block;
  position: relative;
  background: white;
  align-content: center;
  width: 100%;
  max-width: 600px;
  margin: 30px auto;
  bottom: -45px;
  box-shadow: 0 20px 8px 0 rgba(0, 0, 0, 0.2);
}

.styles_minimizedSearchLabel__1GGpO {
  display: inline-block;
  align-content: center;
  width: 100%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 5% 100%, 0 85%);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 2% 100%, 0 85%);
}
.styles_minimizedSearchLabel__1GGpO button {
  cursor: pointer;
}
.styles_minimizedSearchLabel__1GGpO button:focus {
  outline: none;
  cursor: pointer;
}

.styles_topbar__1SKGM {
  position: fixed;
  z-index: 30;
  top: 0;
  left: 0;
  right: 0;
  background: #000;
  width: 100%;
  padding: 0 10px;
  height: var(--topbar-height);
  line-height: var(--topbar-height);
  color: #fff;
  margin: 0;
}
.styles_topboar__1OPIT h1 {
  color: var(--heading-color);
  line-height: 30px;
  letter-spacing: 2px;
  font-weight: 900;
  vertical-align: middle;
  padding-bottom: 10px;
  margin-left: 15px;
  margin-right: 40px;
  text-transform: uppercase;
}
.styles_topbar__1SKGM h1 img {
  margin-right: 10px;

}
.styles_topboar__1OPIT a {
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 1px;
  margin-left: 10px;
}
.styles_topboar__1OPIT .styles_bayerische__116jL {
  padding-right: 20px;
}
.styles_topboar__1OPIT .styles_shadow__1WPIw {
  box-shadow: 0 2px 11px 0 rgba(0, 0, 0, 0.3);
}

.styles_title__2ehts {
  text-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}
.styles_title__2ehts:after {
  color: black;
  content: "marktplatz";
  order: 1;
}

.styles_subtitle__Cnbu2 {
  font-size: 16px;
  line-height: 22.4px;
  text-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}
.styles_subtitle__Cnbu2:after {
  color: black;
  content: " - Verbindet Löwen mit Löwen";
  order: 1;
}

.styles_downLink__2n0ep {
  width: 44px;
  height: 44px;
  border-radius: 22px;
  background: black;
  align-content: center;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  cursor: pointer;
}
.styles_linkFlex__MzIq6 {
  position: relative;
  display:flex;
}
.styles_downLink__2n0ep img {
  margin: 10px;
}

.styles_searchInput__308bA {
  height: 50px;
  width: 60%;
  border: none;
}

.styles_searchButton__1QCeE {
  background: var(--heading-color);
  position: absolute;
  top: 15px;
  color: white;
  height: 30px;
  margin-top: 10px;
  padding: 0 20px;
  border: none;
  /*margin-left: -2px;*/
  display: inline-block;
}
.styles_searchButton__1QCeE:before,
.styles_searchButton__1QCeE:after{
  content: "";
  position: absolute;
  left: 0; 
  right: 0;
  bottom: 100%;
  border-bottom: 10px solid var(--heading-color);
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}
.styles_searchButton__1QCeE:before{
  border-left: 10px solid var(--heading-color);
}
.styles_searchButton__1QCeE:after{
  border-right: none;
  bottom: auto;
  top: 100%;
  border-left: none;
  border-bottom: 10px solid transparent;
  border-top: 10px solid var(--heading-color);
}

.styles_searchButton__1QCeE img {
  vertical-align: unset;
}

.styles_centerVertically__1v6mt {
  top: 0; bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
}

.styles_bayerisch__2RMEB {
  display: inline-block !important;
  cursor: pointer;
}

.styles_headerSearchBar__3y6ED {
  left: 0; right: 0;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.styles_third__1cQH6 {
  width: 33%;
}

.styles_icon__hsNua {
  width: 16px;
  height: 17px;
}

.styles_imgSize__33WZE {
  height: 44px;
  width: 35px;
}

.styles_mobileSearchButton__2UEq- {
  border: none;
  position: relative;
  width: 20%;
  height: 22px;
  display: inline-block;
  margin-left: -1px;
  border-radius: 0;
  background: var(--heading-color);
  margin-right: -1px;
}
.styles_mobileSearchButton__2UEq- img {
  vertical-align: middle;
}

.styles_mobileSearchButton__2UEq-:before,
.styles_mobileSearchButton__2UEq-:after{
  content: "";
  position: absolute;
  left: 0; 
  right: 0;
  bottom: 100%;
  border-bottom: 15px solid var(--heading-color);
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
}
.styles_mobileSearchButton__2UEq-:before{
  border-left: 15px solid var(--heading-color);
}
.styles_mobileSearchButton__2UEq-:after{
  border-right: none;
  bottom: auto;
  top: 100%;
  border-left: none;
  border-bottom: 15px solid transparent;
  border-top: 15px solid var(--heading-color);
}

@media only screen and (min-width: 768px) {
  .styles_bigTopBar__3u-RJ {
    min-height: 450px;
    border-bottom: none;
  }

  .styles_bigTopBar__3u-RJ h1,p {
    text-align: left;
  }
  .styles_bigTopBar__3u-RJ h1{
    font-size: 60px;
  }

  .styles_bigTopBar__3u-RJ .styles_subtitle__Cnbu2{
    font-size: 20px;
  }

  .styles_bigTopBar__3u-RJ .styles_headerLogo__1MleN{
    font-size: 30px;
  }
  .styles_bigTopBar__3u-RJ .styles_mainLogo__3Ccck {
    width: 128px;
    max-width: 128px;
  }
  .styles_bigTopBar__3u-RJ .styles_titleTexts__1WtlS {
    margin-left: 20px;
    width: auto;
  }

  .styles_bigTopBar__3u-RJ .styles_extendedTopBar__3hajN .styles_inputWrapper__3DzVu {
    height: 20px;
  }
  .styles_bigTopBar__3u-RJ .styles_extendedTopBar__3hajN .styles_inputWrapper__3DzVu:before,
  .styles_bigTopBar__3u-RJ .styles_extendedTopBar__3hajN .styles_inputWrapper__3DzVu:after{
    content: "";
    position: absolute;
    left: 0; 
    right: 19.5%;
    bottom: 100%;
    border-bottom: 14px solid white;
    border-left: 15px solid transparent;
    border-right: none;
  }
  .styles_bigTopBar__3u-RJ .styles_extendedTopBar__3hajN .styles_inputWrapper__3DzVu:before{
    border-left: 15px solid white;
  }
  .styles_bigTopBar__3u-RJ .styles_extendedTopBar__3hajN .styles_inputWrapper__3DzVu:after{
    border-right: 15px solid white;
    bottom: auto;
    top: 100%;
    border-bottom: none;
    border-top: 15px solid white;
  }

  .styles_bigTopBar__3u-RJ .styles_searchLabel__1_eVU{
    bottom: 0;
  }

  .styles_bigTopBar__3u-RJ input {
    padding: 0 10px;
    font-weight: normal;
    color: black;
  }

  .styles_topbar__1SKGM {
    padding: 0 30px !important;
  }
  .styles_topbar__1SKGM a {
    margin-left: 20px;
  }
}

@media only screen and(min-width: 768px) and(max-width: 1200px) {
  .styles_titleContainer__33ep1 {
    width: 66% !important;
  }
}

.styles_footer__cVV_0 {
  z-index: 20;
  font-weight: bold;
  bottom: 0;
  padding: 0;
  width: 100%;
  margin-top: auto;
  margin-bottom: 0;
  background-color: var(--heading-color);
}

.styles_top__mk9DC {
  text-align: center;
  background-color: var(--heading-color);
  width: 100%;
  text-transform: uppercase;
  font-family: 'Anton', sans-serif !important;
  box-shadow: 0px -3px 20px rgba(0,0,0,0.3);
  z-index: 1;

}

.styles_topColumn__1ZWRt {
  color: white;
  padding: 12px;
  text-align: center;
  letter-spacing: 1px;
  font-family: 'Anton', sans-serif !important;
  font-size: 20px;
  font-weight: 400;
  line-height: 21px;
} 

.styles_mobileTop__goC7G {
  color: white;
  padding: 24px 12px;
  text-align: center;
  letter-spacing: 1px;
  font-family: 'Anton', sans-serif !important;
  font-size: 20px;
  font-weight: 400;
  line-height: 21px;
}

.styles_images__1YA1m {
  align-content: center;
  background: white;
  padding: 0 !important;
}
.styles_images__1YA1m img {
  display: block;
  left: 0; right: 0;
  margin-left: auto;
  margin-right: auto;
}

.styles_rights__2fQvA {
  bottom: 0;
  padding: 14px;
  background: var(--heading-color);
  color: white;
  letter-spacing: 0.5px;
  font-family: 'Anton', sans-serif !important;
  font-size: 12px;
  width: 100%;
  text-transform: none;
  font-weight: normal;
}

.styles_borderedElement__4Ur_m {
  border-left: #7fc1ee solid 2px;
  border-right: #7fc1ee solid 2px;
}

.styles_borderedElementGrey__wcMgx{
  border-left: #dedede solid 2px;
  border-right: #dedede solid 2px;
}

.styles_headPartner__1KNKx {
  padding: 40px 20px;
}
.styles_headPartner__1KNKx a img {
  max-width: -webkit-fill-available;
  height: auto;
}

.styles_outfitter__23KRc {
  padding: 40px 20px;
}
.styles_outfitter__23KRc a img {
  max-width: -webkit-fill-available;
  height: auto;
}

.styles_premiumPartner__1fTze{
  padding: 0 20px !important;
}

.styles_partnerImage__1Geft {
  align-self: center;
  padding-top: 24px;
  padding-bottom: 24px;
  width: 20px;
}
.styles_partnerImage__1Geft a img {
  max-width: 100%;
  height: auto;
  filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 3.5+ */
  filter: gray; /* IE6-9 */
  -webkit-filter: grayscale(100%); /* Chrome 19+ & Safari 6+ */
}
.styles_partnerImage__1Geft a img:hover {
  filter: none;
  -webkit-filter: grayscale(0%);
}
    
.styles_nikeLogo__2WDMu {
  background: #FFF;
}

.styles_nikeLogo__2WDMu:hover {
  background: #ABABAB;
}

.styles_bottom__gMzgi {
  bottom: 0;
  background-color: black;
  color:white;
  padding: 20px;
  text-align: left;
  width: 100%;

  a {
    color: white;
    cursor: pointer;
    text-align: left;
    font-weight: 300;
    line-height: 20px;
    font-size: 16px;
    letter-spacing: 0.5;
  }

  a:hover {
    color: var(--heading-color);
    text-decoration: none;
  }

  div {
    border-right: 2px solid var(--heading-color);
  }
}

.styles_buttons__2k6wC {
  float: right;
  vertical-align: middle;
  margin: 10px;
  font-family: 'Anton' !important;
}

.styles_footerButton__PyUuj {
  background: transparent;
  border-color: transparent;
  color: white;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  font-size: 20px;
  line-height: 24px !important;
  margin: 12px;
  cursor: pointer;
  font-family: 'Anton' !important;
  font-weight: bold;
  color: var(--heading-color);
}

.styles_noBorder__390KM {
  border: none;
}

@media only screen and (min-width: 1200px) {
  .styles_borderedElement__4Ur_m {
    margin: 0px;
  }
  .styles_headPartner__1KNKx {
    padding: 60px 20px;
  }
  .styles_outfitter__23KRc {
    padding: 60px 20px;
  }
  .styles_rights__2fQvA {
    display: inline-block;
    margin: 10px;
    padding: 0;
    font-size: 14px;
    width: 30%;
    background: transparent;
  }
  .styles_bottom__gMzgi div {
    border: none;
  }
}

.styles_filterView__EmPpv {
  background: black;
  color: white;
  position: relative;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  right: 30px;
  left: 10px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 0;
}

.styles_filterButton__3NRTX {
  background: var(--heading-color);
  margin: 20px;
  padding: 10px 0;
  vertical-align: middle;
  color: white;
  cursor: pointer;
}
.styles_filterButton__3NRTX h2 {
  text-transform: uppercase;
  top: 0; bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  vertical-align: middle;
  padding: 15px;
  display: inline-block;
  font-weight: bold;
}

.styles_filterIcon__3bix2 {
  float: right;
  display: inline-block;
  margin-right: 20px;
  top: 50%;
  margin-top: 12px;
}

.styles_category__3rWg6 {
  position: relative;
  background: white;
  /*border: 2px solid var(--heading-color);*/
  display: inline-block;
  color: var(--heading-color);
  margin: 20px 5px;
  padding: 0 15px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
}
.styles_category__3rWg6:before,
.styles_category__3rWg6:after{
  content: "";
  position: absolute;
  left: 0; 
  right: 0;
  bottom: 100%;
  border-bottom: 10px solid white;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}
.styles_category__3rWg6:before{
  border-left: 10px solid white;
}
.styles_category__3rWg6:after{
  border-right: none;
  bottom: auto;
  top: 100%;
  border-left: none;
  border-bottom: 10px solid transparent;
  border-top: 10px solid white;
}

.styles_categoryActive__3kV_n,
.styles_categoryActive__3kV_n:focus {
  background: var(--heading-color);
  color: white;
}
.styles_categoryActive__3kV_n:before,
.styles_categoryActive__3kV_n:after{
  content: "";
  position: absolute;
  left: 0; 
  right: 0;
  bottom: 100%;
  border-bottom: 10px solid var(--heading-color);
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}
.styles_categoryActive__3kV_n:before{
  border-left: 10px solid var(--heading-color);
}

.styles_categoryActive__3kV_n:after{
  border-right: none;
  bottom: auto;
  top: 100%;
  border-left: none;
  border-bottom: 10px solid transparent;
  border-top: 10px solid var(--heading-color);
}

@media screen and (min-width: 768px) {
  .styles_category__3rWg6 {
    display: block;
    margin: 20px 0;
    border: none;
    background: transparent;
    color: white;
    position: relative;

  }
  .styles_category__3rWg6:before,
  .styles_category__3rWg6:after{
    display: none;
  }
  .styles_filterView__EmPpv {
    position: relative;
    z-index: 2;
    height: auto;
    padding: 2px 0;
    width: auto;
    margin-left: 0;
    margin-right: 0;
    left: 0;
    top: 0;
  }

  .styles_filterButton__3NRTX {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.styles_containersContainer__3EA56 {
  width: 100%;
}

.styles_container__1LEz7 {
  display: inline-block;
  position: absolute;
  bottom: 0;
  margin-bottom: 0;
  height: auto;
  vertical-align: bottom;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0 15px;
  width: 100%;
  white-space: nowrap;
}

.styles_item__iQmKL {
  position: relative;
  display: inline-block;
  vertical-align: bottom;
  width: 100%;
  height: auto;
  padding: 0;
  margin: 30px 0;
  bottom: 0;
  background-color: white;
  color: var(--heading-color);
  cursor: pointer;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3), inset 0 -5px 0 0 #5aaadf;
}
.styles_item__iQmKL .styles_title__3s_kB {
  letter-spacing: 1px;
  font-family: Anton,sans-serif!important;
  font-weight: 400;
  font-size: 18px;
  color: black;
  text-transform: uppercase;
}
.styles_item__iQmKL .styles_subtitle__3FDMV {
  font-weight: normal;
  font-size: 14px;
}
.styles_item__iQmKL h2 {
  margin-top: 0px;
  margin-bottom: 0px;
  text-transform: uppercase;
}
.styles_item__iQmKL:hover{
  &, h2{
    color: black;
  }
}

.styles_itemImage__1lH_j {
  display: inline-block;
  margin: 0;
  width: 33%;
  height: 105px;
  max-width: 100px;
  overflow: hidden; 
}
.styles_itemImage__1lH_j > img {
  height: 105px;
}

.styles_itemImage__1lH_j div,
.styles_itemImage__1lH_j .styles_graphcms-image-outer-wrapper__15Yoz,
.styles_itemImage__1lH_j .styles_graphcms-image-wrapper__jAlzA{
  height: 105px;
}

.styles_itemTitle__13WOp {
  display: inline-block;
  padding: 10px;
  vertical-align: top;
  width: 66%;
  height: 100%;
}

.styles_expandedItem__Kgv6k {
  position: relative;
  display: inline-block;
  vertical-align: bottom;
  width: 100%;
  height: auto;
  z-index: 10;
  min-height: 510px;
  padding: 0;
  margin: 30px 0;
  bottom: 0;
  top: -15px;
  background-color: white;
  color: var(--heading-color);
  cursor: pointer;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
}

.styles_expandedItem__Kgv6k:after{
  content: "";
  position: absolute;
  left: 0; 
  right: 0;
  bottom: 100%;
  border-bottom: 15px solid white;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
}
.styles_expandedItem__Kgv6k:after{
  border-right: 15px solid white;
  bottom: auto;
  top: 100%;
  border-bottom: none;
  border-top: 15px solid white;
}
.styles_expandedItem__Kgv6k .styles_image__3lRER {
  /*margin-top: -15px;*/
  width: 100%;
}
.styles_expandedItem__Kgv6k .styles_expandedItemContent__3l3or{
  padding: 10px 10px 0 10px;
}
.styles_expandedItem__Kgv6k hr {
  background-color: black;
  border: none;
}
.styles_expandedItem__Kgv6k h1, h2, h3 {
  text-align: left;
}
.styles_expandedItem__Kgv6k h2, h3 {
  margin-bottom: 0;
  margin-top: 0;
}
.styles_expandedItem__Kgv6k .styles_title__3s_kB {
  letter-spacing: 1px;
  font-family: Anton,sans-serif!important;
  font-weight: 400;
  color: black;
  font-size: 18px;
  text-transform: uppercase;
}
.styles_expandedItem__Kgv6k .styles_subtitle__3FDMV {
  color: var(--heading-color);
  font-weight: bold;
  font-size: 14px;
}
.styles_expandedItem__Kgv6k .styles_phone__3qs9Q {
  color: var(--heading-color);
  margin-bottom: 0;
  text-align: left;
  font-size: 16px;
  line-height: 16px;
  display: inline-block;
  padding-top: 2px;
  width: 100%;
}
.styles_expandedItem__Kgv6k .styles_phone__3qs9Q img {
  display: inline-block;
  margin-right: 10px;
}
.styles_expandedItem__Kgv6k img {
  position: relative;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}
.styles_expandedItem__Kgv6k .styles_partnerLogo__uKnun {
  z-index: 5;
  background: white !important;
  float: left;
  left: 15px;
  top: -15px;
  padding: 5px;
  width: 120px;
  margin-bottom: -100px;
  border: 1px solid var(--heading-color);
}
.styles_expandedItem__Kgv6k .styles_content__pK9yS {
  text-align: center;
}
.styles_expandedItem__Kgv6k .styles_lineLeft__1CEnC {
  float: left;
  width: 50%;
  text-align: left;
  line-height: 1.4;
  font-size: 0.9em;
}
.styles_expandedItem__Kgv6k .styles_lineRight__3wkFP {
  float: right;
  width: 50%;
  line-height: 1.4;
  text-align: right;
  font-size: 0.9em;
}
.styles_expandedItem__Kgv6k div {
  color: black;
  position: relative;
  display: block;
  font-size: 13px;
}
.styles_expandedItem__Kgv6k .styles_hr__fQhsv {
  margin-top: 10px;
  margin-bottom: 10px;
  float: left;
  width: 100%;
  background-color: var(--heading-color);
  height: 3px;
  position: relative;
}



.styles_loadMoreDiv__3XDM7 {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  align-content: center;
  text-align: center;
}
.styles_moreButton__1sQxH {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 30px;
  margin-bottom: 20px;
  align-self: center;
  background: transparent;
  color: white;
  border: none;
  cursor: pointer;
  -webkit-appearance: none;
}
.styles_moreButton__1sQxH p {
  padding-bottom: 5px;
  margin-bottom: 0;
}
.styles_filterButton__2t97z {
  margin: 20px;
}

.styles_slider__3ZXUW {
  position: relative;
  height: auto;
  vertical-align: bottom;
}

.styles_sliderContainer__260so {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.styles_slickSlide__3qFCQ{
  padding: 0 5px;
}


@media screen and (min-width: 768px) {
  .styles_container__1LEz7 {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px;
    position: relative;
    display: block;
    top: 0;
    bottom: 0;
    margin: 0;
    height: 100%;
    width: 100%;
    background: none;
    white-space: normal;
  }

  .styles_item__iQmKL {
    display: block;
    margin: 50px 10px;
    padding: 0 15px;
    width: auto;
    height: auto;
    background: var(--heading-color);
    color: white;
    box-shadow: none;
  }
  .styles_item__iQmKL .styles_title__3s_kB{
    color: white;
    font-size: 20px !important;
  }
  .styles_item__iQmKL:before,
  .styles_item__iQmKL:after {
    content: "";
    position: absolute;
    left: 0; 
    right: 0;
    bottom: 100%;
    border-bottom: 15px solid var(--heading-color);
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
  }
  .styles_item__iQmKL:before{
    border-left: 15px solid var(--heading-color);
  }
  .styles_item__iQmKL:after{
    border-right: 15px solid var(--heading-color);
    bottom: auto;
    top: 100%;
    border-bottom: none;
    border-top: 15px solid var(--heading-color);
  }
  .styles_item__iQmKL .styles_itemTitle__13WOp{
    width: 100%;
  }

  .styles_item__iQmKL:first-child{
    margin-top: 0;
  }

  .styles_itemTitle__13WOp {
    padding: 0;
  }

  .styles_expandedItem__Kgv6k {
    position: relative;
    margin: 50px 10px;
    height: auto;
    top: 0;
    max-width: unset;
    width: auto;
    white-space: normal;
    display: inherit;
  }
  .styles_expandedItem__Kgv6k .styles_title__3s_kB{
    font-size: 20px !important;
  }

  .styles_expandedItem__Kgv6k:first-child{
    margin-top: 10px;
  }
}

.styles_wrapper__3_hf1 {
  top: var(--topbar-height);
  max-height: 500px;
}

.styles_map__3acRc {
  position: absolute;
}
@custom-media --screen-phone (max-width: 35.5em);
@custom-media --screen-phone-lg (min-width: 35.501em);

.styles_sidebar__1NqCL {
  left: 0; 
  width: 100%;
  position: relative;
  background-color: black;
  padding: 0;
  overflow-x: auto;
}
.styles_sidebar__1NqCL h1 {
  font-size: 24px;
}
.styles_sidebar__1NqCL h2 {
  font-size: 16px;
}
.styles_sidebar__1NqCL h3 {
  font-size: 18px;
}

.styles_mobileListingDiv__3eRRt {
  position: absolute;
  bottom: 0; top: 0;
  margin-top: auto;
  margin-bottom: 10px;
  overflow-x: auto;
  overflow-y: hidden;
  height: 100px;
}
.styles_mobileListing__3lJXI {
  display: inline-block;
  width: 100%;
}

.styles_wrapper__2_i-F {
  overflow-y: scroll;
  margin: 0;
}

.styles_content__2Guhx {
  height: var(--mobile-map-height);
  position: relative;
}

.styles_loadingOverlay__26xMc {
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  text-align: center;
  align-content: center;
  vertical-align: center;
  z-index: 5;
}
.styles_loadingOverlay__26xMc p {
  position: absolute;
  top: 0; left: 0;
  bottom: 0; right: 0;
  margin: auto !important;
  height: 50px;
}

@media screen and (min-width: 768px) {
  .styles_content__2Guhx {
    height: var(--map-height);
  }

  .styles_sidebar__1NqCL {
    left: 0; 
    overflow: auto;
    height: var(--map-height);
    width: 330px;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1;
  }
}


.styles_wrapper__1vwYI {
  overflow-y: scroll;
  margin: 0;

  @media (--screen-phone-lg) {
    flex-direction: row;
  }
}

.styles_content__WaJYo {
  height: var(--map-height);
  position: relative;
  
  @media (--screen-phone-lg) {
    flex: 2 1;
    order: 2;
  }
}

.styles_wrapper__3vyeE{ 
  top: var(--topbar-height);
  overflow-x: hidden;
}

