@import url("styles/colors.css");

@font-face {
  font-family: "Albert";
  src: url("http://www.tsv1860.de/i/fonts/fs_albert_pro-bold_0-webfont.woff2");
}

*,
*:after,
*:before {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}

body {
  overflow: scrollY;
  color: var(--dark);
  font-smoothing: antialiased;
  padding: 0;
  margin: 0;
}

html * {
  font-family: 'Albert', sans-serif !important;
}

p, div {
  font-family: 'Albert', sans-serif !important;
}

input {
  border-radius: 0;
  border: none;
}

h1 {
  font-size: 18px;
  font-family: Anton,sans-serif!important;
  font-weight: 400 !important;
  letter-spacing: 1px;
}
h2 {
  font-size: 15px;
  font-family: Anton,sans-serif!important;
  font-weight: 400 !important;
  letter-spacing: 1.5px;
}
h3 {
  font-size: 16px;
  font-weight: 400 !important;
}
h4 {
  font-size: 32px;
  font-weight: 400 !important;
  font-family: 'Anton', sans-serif !important;
  letter-spacing: 1.5px;
}

@media only screen and (min-width: 768px) {
  h1 {
    font-size: 30px;
  }
  h2 {
    font-size: 16px;
  }
  h3 {
    font-size: 14px;

  }
}

:global(.slick-list){
  overflow: visible !important;
}

:global(.slick-track) {
/*height: 200px;*/
}

:global(.slick-slide) {
  float: none !important;
  display: inline-block !important;
  vertical-align: bottom !important ;
}
